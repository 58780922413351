module.exports = [{
      plugin: require('../node_modules/@outdoormap/gatsby-plugin-brand-sites/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://api.naturkartan.se","apiKey":"311ae247-83fe-427a-be6a-327ef64a9a2d","guideId":"208","siteUrl":"https://en.unionsleden.com","lang":"en","typesCategories":{"stages":["31"],"stays":["55","56"],"eatDrink":["54"],"service":["72","73","119"],"packages":["117"],"seeDo":[]},"typesCategoriesIncluded":{"stages":["31"],"stays":["55","56"],"eatDrink":["54"],"service":["72","73","119"],"packages":["117"],"seeDo":[]},"imagePlaceholderColor":"#29292b","templates":{"type":"/build/dcbd2eb9-e7af-433f-896c-471840b2854c/src/templates/type.js","site":"/build/dcbd2eb9-e7af-433f-896c-471840b2854c/src/templates/site.js","page":"/build/dcbd2eb9-e7af-433f-896c-471840b2854c/src/templates/page.js","articles":"/build/dcbd2eb9-e7af-433f-896c-471840b2854c/src/templates/articles.js","articlesPost":"/build/dcbd2eb9-e7af-433f-896c-471840b2854c/src/templates/articles-post.js","search":"/build/dcbd2eb9-e7af-433f-896c-471840b2854c/src/templates/search.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"en","name":"Unionsleden","short_name":"Unionsleden","description":"Cykla i två länder i ett område rikt på naturnära upplevelser","theme_color":"#29292b","background_color":"#fff","icon":"static/meta-images/icon.png","start_url":"/","display":"standalone","cache_busting_mode":"none","include_favicon":false,"legacy":false,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
